import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo192.png';
import Breve from '../assets/breve.png';
import Email from '../assets/email.png';
import Zap from '../assets/zap.png';
function NameForm() {
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/question', { state: { name } });
    };

    return (
        <section  style={{
            backgroundImage: 'url("bg.png")',
        }} className='min-h-screen flex items-center bg-bo justify-end bg-black bg-cover lg:bg-contain bg-no-repeat py-12 px-4 sm:px-6 lg:px-8'>        
        <div className='container flex flex-col gap-4 justify-evenly h-screen items-center'>
            <img src={Breve} alt='logo' className='w-2/3 mb-20 mx-auto'/>
            <div className='flex flex-col gap-2 justify-center items-center'>
                <div className='text-xl flex flex-row lg:text-3xl text-[#fff] font-black rounded-full w-auto justify-center items-center gap-3'>
                    <img src={Email} alt='logo' className='w-[30px]'/>
                    <a href='mailto:contato@figueiraproduções.com.br'>contato@figueiraproduções.com.br</a>
                </div>
                <div className='text-xl flex flex-row lg:text-3xl text-[#fff] font-black rounded-full w-auto justify-center items-center gap-3'>
                    <img src={Zap} alt='logo' className='w-[30px]'/>
                    <a href='https://api.whatsapp.com/send?phone=5511972045308&text=Ola%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20os%20eventos.'>+55 11 97204-5308</a>
                </div>
            </div>
            <img src={Logo} alt='logo' className='w-[200px] mb-20 mx-auto'/>
        </div>
        </section>
    );
}

export default NameForm;